
import Vue from 'vue'
import SERVICES from '@/constants/services'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'mobNavigationBar',
  data: () => ({
    navList: [
      { title: 'Мой аккаунт', to: { name: 'whitelistID' } },
      { title: 'Правовая информация', to: { name: 'whitelistIDLegalInformation' } }
    ]
  }),
  computed: {
    ...mapGetters('user', ['getIsAuthenticated', 'getUserData'])
  },
  methods: {
    redirectToAcceptor (): void {
      this.$store.commit('setAppIsRedirecting', true)
      window.open(sessionStorage.getItem('acceptor') ?? SERVICES.ACCEPTOR_BASE_URL, '_self')
    },
    showChat () {
      window.location.href = `${SERVICES.PASSPORT_URL}/id/#chatraChatExpanded`
    }
  },
  beforeMount () {
    if (!this.isTrustedEnvironment) {
      this.navList.splice(1, 0, { title: 'О разработчике', to: { name: 'whitelistIDAbout' } })
    }
  }
})
